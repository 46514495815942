@use 'sass:map';
@use '@angular/material' as mat;
@use 'utils' as utils;

@mixin color($config) {
  //$tertiary: mat.get-color-from-palette(map.get($config, 'neutral'));
  $tertiary: theme('colors.tertiary');

  // Enable gray indicator for inactive tabs
  .mdc-tab:not(.mdc-tab--active) {
    .mdc-tab-indicator__content--underline {
      --mdc-tab-indicator-active-indicator-color: #{$tertiary};
      --mdc-tab-indicator-active-indicator-height: 1px;
      opacity: 1;
    }
  }
}

@mixin layout() {
  .mat-mdc-tab-body {
    .mat-mdc-tab-body-content {
      @apply flex flex-col p-3;
    }

    &.pad-none {
      .mat-mdc-tab-body-content {
        @apply p-0;
      }
    }
  }
}

@mixin core-theme($theme) {
  $color-config: utils.get-colors($theme);

  @include color($color-config);
  @include layout();
}
