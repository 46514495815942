@use 'sass:map';

@mixin layout() {
  .mat-mdc-dialog-actions {
    // Fix actions padding to match title/content
    padding: 0 24px 20px 24px !important;

    // Right align buttons
    justify-content: end !important;

    // Space between buttons
    @apply space-x-2;
  }
}

@mixin core-theme($theme) {
  @include layout();
}
