// https://material.angular.io/guide/material-3
@use 'sass:map';
@use '@angular/material' as mat;

// Plus imports for other components in your app.
@use 'material/mat-button-toggle-theme';
@use 'material/mat-core.component-theme';
@use 'material/mat-progress-spinner.component-theme';
@use 'material/mat-select.component-theme';
@use 'material/mat-slider.component-theme';
@use 'material/mat-tab.component-theme';

/* stylelint-disable-next-line at-rule-empty-line-before */
@use 'dialogs' as dialogs;
@use 'form-fields' as formFields;
@use 'icons' as icons;
@use 'overlays' as overlays;
@use 'spinners' as spinners;
@use 'tabs' as tabs;
@use 'tooltips' as tooltips;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core;

// Define palettes
$nzc-light-primary: mat.m2-define-palette(mat.$m2-light-blue-palette, 900);
$nzc-light-accent: mat.m2-define-palette(mat.$m2-amber-palette, A200, A100, A400);
$nzc-light-warn: mat.m2-define-palette(mat.$m2-orange-palette);
$nzc-light-danger: mat.m2-define-palette(mat.$m2-red-palette, 700);
$nzc-light-success: mat.m2-define-palette(mat.$m2-green-palette, 700);

// Picked a color palette that stands out, so we can see where the Modus colors need to be applied.
$nzc-light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$red-palette,
    ),
    typography: (
      brand-family: 'Open Sans',
      plain-family: 'Open Sans',
      regular-weight: 400,
      medium-weight: 600,
      bold-weight: 700,
    ),
  )
);

// Merge custom config onto theme
$nzc-light-theme: map.merge(
  $nzc-light-theme,
  (
    // Define icon size and factors
    icons:
      (
        size: 24px,
        factors: (
          sm: 0.5,
          md: 0.75,
          lg: 1.25,
          xl: 1.5,
          2xl: 2,
          3xl: 3.5,
          4xl: 4,
        ),
      ),
    // Define additional colors as we can't add colors to default 'color' map
    colors:
      (
        primary: $nzc-light-primary,
        accent: $nzc-light-accent,
        warn: $nzc-light-warn,
        danger: $nzc-light-danger,
        success: $nzc-light-success,
      )
  )
);

// Apply the base theme at the root, so it will be inherited by the whole app.
html {
  @include mat.core-theme($nzc-light-theme);
  @include mat.button-toggle-theme($nzc-light-theme);
  @include mat.progress-spinner-theme($nzc-light-theme);
  @include mat.select-theme($nzc-light-theme);
  @include mat.slider-theme($nzc-light-theme);
  @include mat.tabs-theme($nzc-light-theme);

  // @include mat.all-component-themes($nzc-light-theme);
  @include dialogs.core-theme($nzc-light-theme);
  @include formFields.core-theme($nzc-light-theme);
  @include icons.core-theme($nzc-light-theme);
  @include overlays.core-theme($nzc-light-theme);
  @include spinners.core-theme($nzc-light-theme);
  @include tabs.core-theme($nzc-light-theme);
  @include tooltips.core-theme($nzc-light-theme);
}
