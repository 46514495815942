@use 'sass:map';
@use '@angular/material' as mat;
@use 'utils' as utils;

@mixin color($config) {
  $success: mat.m2-get-color-from-palette(map.get($config, 'success'));
  $warn: mat.m2-get-color-from-palette(map.get($config, 'warn'));
  $error: mat.m2-get-color-from-palette(map.get($config, 'danger'));

  .modus-tooltip-panel {
    .modus-tooltip {
      &.success {
        background-color: #{$success};
      }

      &.warn {
        background-color: #{$warn};
      }

      &.error {
        background-color: #{$error};
      }
    }

    &-below .modus-tooltip {
      &.success::before {
        border-bottom-color: #{$success};
      }

      &.warn::before {
        border-bottom-color: #{$warn};
      }

      &.error::before {
        border-bottom-color: #{$error};
      }
    }
  }
}

@mixin layout() {
  // Fix for modus-tooltip arrow position not correct
  .modus-tooltip-panel {
    .modus-tooltip {
      max-width: 250px;
      text-wrap: initial;

      &.arrow-left::before {
        left: 16px;
      }

      &.arrow-right::before {
        left: unset;
        right: 7px;
      }
    }
  }
}

@mixin core-theme($theme) {
  $color-config: utils.get-colors($theme);

  @include color($color-config);
  @include layout();
}
