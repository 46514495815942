@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
@import 'styles/theme.scss';
@import '../../../libs/modus/src/modus.scss';

html,
body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background: #fff;
}

@layer utilities {
  // TODO: remove once tailwind supports break-anywhere
  // https://github.com/tailwindlabs/tailwindcss/pull/12128
  .break-anywhere {
    @supports (overflow-wrap: anywhere) {
      overflow-wrap: anywhere;
    }

    @supports not (overflow-wrap: anywhere) {
      word-break: break-word;
    }
  }
}
