@use '../trimble-colors' as colors;

mat-button-toggle-group {
  // Layout
  --mat-standard-button-toggle-height: 32px;
  --mat-standard-button-toggle-shape: 4px; // border-radius

  // Label
  --mat-standard-button-toggle-label-text-font: 'Open Sans';
  --mat-standard-button-toggle-label-text-line-height: 1.25rem;
  --mat-standard-button-toggle-label-text-size: 0.875rem;
  --mat-standard-button-toggle-label-text-tracking: 0.006rem;
  --mat-standard-button-toggle-label-text-weight: 600;

  // State
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.08;
}

html {
  &.light {
    mat-button-toggle-group {
      // Standard
      --mat-standard-button-toggle-background-color: transparent;
      --mat-standard-button-toggle-divider-color: #{colors.$gray-1};
      --mat-standard-button-toggle-state-layer-color: #00548a;
      --mat-standard-button-toggle-text-color: #00548a;

      // Selected
      --mat-standard-button-toggle-selected-state-background-color: rgb(0 99 163 / 20%);
      --mat-standard-button-toggle-selected-state-text-color: #00548a;

      // Disabled
      --mat-standard-button-toggle-disabled-selected-state-background-color: rgba(32, 26, 25, 0.12);
      --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(32, 26, 25, 0.38);
      --mat-standard-button-toggle-disabled-state-background-color: transparent;
      --mat-standard-button-toggle-disabled-state-text-color: rgba(32, 26, 25, 0.38);
    }
  }

  &.dark {
    mat-button-toggle-group {
    }
  }
}
