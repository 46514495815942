/* stylelint-disable selector-class-pattern */
@use '../trimble-colors' as colors;

mat-slider {
  // label
  --mdc-slider-label-label-text-font: 'Open Sans';
  --mdc-slider-label-label-text-size: 0.75rem;
  --mdc-slider-label-label-text-line-height: 1rem;
  --mdc-slider-label-label-text-tracking: 0.031rem;
  --mdc-slider-label-label-text-weight: 600;

  // active-track
  --mdc-slider-active-track-height: 8px;
  --mdc-slider-active-track-shape: 8px;

  // handle
  --mdc-slider-handle-height: 18px;
  --mdc-slider-handle-shape: 18px;
  --mdc-slider-handle-width: 18px;

  // inactive-track
  --mdc-slider-inactive-track-height: 8px;
  --mdc-slider-inactive-track-shape: 8px;

  // ---
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-container-shape: 4px;
  --mdc-slider-with-tick-marks-container-size: 4px;

  // indicator
  --mat-slider-value-indicator-width: 28px;
  --mat-slider-value-indicator-height: 28px;
  --mat-slider-value-indicator-caret-display: none;
  --mat-slider-value-indicator-border-radius: 50% 50% 50% 0;
  --mat-slider-value-indicator-padding: 0;
  --mat-slider-value-indicator-text-transform: rotate(45deg);
  --mat-slider-value-indicator-container-transform: translateX(-50%) rotate(-45deg);

  &.mat-mdc-slider {
    .mdc-slider__track--inactive {
      opacity: 1;
    }

    .mdc-slider__thumb-knob {
      border-width: 2px;
      background-color: #{colors.$white};
    }

    .mdc-slider__thumb--focused .mdc-slider__thumb-knob {
      background-color: #{colors.$blue-pale};
    }

    &.mdc-slider--disabled {
      .mdc-slider__thumb-knob {
        background-color: #{colors.$blue-pale};
      }
    }

    .mat-ripple {
      display: none;
    }
  }
}

html {
  &.light {
    mat-slider {
      --mdc-slider-handle-color: #{colors.$trimble-blue};
      --mdc-slider-focus-handle-color: #{colors.$trimble-blue};
      --mdc-slider-hover-handle-color: #{colors.$trimble-blue};
      --mdc-slider-active-track-color: #{colors.$trimble-blue};
      --mdc-slider-inactive-track-color: #{colors.$trimble-blue};

      // ---
      --mdc-slider-with-tick-marks-inactive-container-color: #{colors.$trimble-blue};
      --mdc-slider-with-tick-marks-active-container-color: #{colors.$trimble-blue};

      // ---
      --mdc-slider-disabled-active-track-color: #{colors.$trimble-blue};
      --mdc-slider-disabled-handle-color: #{colors.$trimble-blue};
      --mdc-slider-disabled-inactive-track-color: #{colors.$trimble-blue};

      // ---
      --mdc-slider-label-container-color: #{colors.$gray-8};
      --mdc-slider-label-label-text-color: #{colors.$white};
      --mdc-slider-with-overlap-handle-outline-color: #{colors.$white};
      --mdc-slider-with-tick-marks-disabled-container-color: #{colors.$trimble-blue};
      --mdc-slider-handle-elevation: 1;
      --mdc-slider-handle-shadow-color: #{colors.$trimble-gray};

      // ---
      --mdc-slider-with-tick-marks-active-container-opacity: 0.38;
      --mdc-slider-with-tick-marks-inactive-container-opacity: 0.38;

      // ---
      --mat-slider-ripple-color: transparent;
      --mat-slider-hover-state-layer-color: rgb(192 1 0 / 5%);
      --mat-slider-focus-state-layer-color: rgb(192 1 0 / 20%);
      --mat-slider-value-indicator-opacity: 1;
    }
  }

  &.dark {
    mat-slider {
      --mdc-slider-handle-color: #{colors.$highlight-blue};
      --mdc-slider-focus-handle-color: #{colors.$highlight-blue};
      --mdc-slider-hover-handle-color: #{colors.$highlight-blue};
      --mdc-slider-active-track-color: #{colors.$highlight-blue};
      --mdc-slider-inactive-track-color: #{colors.$highlight-blue};

      // ---
      --mdc-slider-with-tick-marks-inactive-container-color: #{colors.$highlight-blue};
      --mdc-slider-with-tick-marks-active-container-color: #{colors.$highlight-blue};

      // ---
      --mdc-slider-disabled-active-track-color: #{colors.$highlight-blue};
      --mdc-slider-disabled-handle-color: #{colors.$highlight-blue};
      --mdc-slider-disabled-inactive-track-color: #{colors.$highlight-blue};

      // ---
      --mdc-slider-label-container-color: #{colors.$gray-8};
      --mdc-slider-label-label-text-color: #{colors.$white};
      --mdc-slider-with-overlap-handle-outline-color: #{colors.$white};
      --mdc-slider-with-tick-marks-disabled-container-color: #{colors.$highlight-blue};
      --mdc-slider-handle-elevation: 1;
      --mdc-slider-handle-shadow-color: #{colors.$trimble-gray};

      // ---
      --mdc-slider-with-tick-marks-active-container-opacity: 0.38;
      --mdc-slider-with-tick-marks-inactive-container-opacity: 0.38;

      // ---
      --mat-slider-ripple-color: transparent;
      --mat-slider-hover-state-layer-color: rgb(192 1 0 / 5%);
      --mat-slider-focus-state-layer-color: rgb(192 1 0 / 20%);
      --mat-slider-value-indicator-opacity: 1;
    }
  }
}
