@use '../trimble-colors' as colors;

html {
  --mat-ripple-color: transparent;

  mat-option {
    --mat-option-label-text-font: 'Open Sans';
    --mat-option-label-text-line-height: 1.25rem;
    --mat-option-label-text-size: 12px;
    --mat-option-label-text-tracking: 0.006rem;
    --mat-option-label-text-weight: 400;
  }

  &.light {
    --mat-app-background-color: #{colors.$white};
    --mat-app-text-color: #{colors.$trimble-gray};

    mat-option {
      --mat-option-selected-state-label-text-color: #{colors.$trimble-gray};
      --mat-option-label-text-color: #{colors.$trimble-gray};
      --mat-option-hover-state-layer-color: #{colors.$gray-0};
      --mat-option-focus-state-layer-color: #{colors.$gray-light};
      --mat-option-selected-state-layer-color: #{colors.$blue-pale};
    }

    mat-pseudo-checkbox {
      // mat-full-pseudo-checkbox
      --mat-full-pseudo-checkbox-selected-icon-color: #{colors.$blue-light};
      --mat-full-pseudo-checkbox-selected-checkmark-color: #{colors.$white};
      --mat-full-pseudo-checkbox-unselected-icon-color: #{colors.$gray-4};
      --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #{colors.$white};
      --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #{colors.$gray-4};
      --mat-full-pseudo-checkbox-disabled-selected-icon-color: #{colors.$gray-4};

      // mat-minimal-pseudo-checkbox
      --mat-minimal-pseudo-checkbox-selected-checkmark-color: #{colors.$trimble-gray};
      --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #{colors.$gray-4};
    }
  }

  &.dark {
    --mat-app-background-color: #{colors.$trimble-gray};
    --mat-app-text-color: #{colors.$white};

    mat-option {
      --mat-option-selected-state-label-text-color: #{colors.$white};
      --mat-option-label-text-color: #{colors.$white};
      --mat-option-hover-state-layer-color: #{colors.$gray-8};
      --mat-option-focus-state-layer-color: #21202d;
      --mat-option-selected-state-layer-color: #1a4c67;
    }

    mat-pseudo-checkbox {
      // mat-full-pseudo-checkbox
      --mat-full-pseudo-checkbox-selected-icon-color: #{colors.$white};
      --mat-full-pseudo-checkbox-selected-checkmark-color: #{colors.$white};
      --mat-full-pseudo-checkbox-unselected-icon-color: #{colors.$gray-4};
      --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #{colors.$gray-8};
      --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #{colors.$gray-4};
      --mat-full-pseudo-checkbox-disabled-selected-icon-color: #{colors.$gray-4};

      // mat-minimal-pseudo-checkbox
      --mat-minimal-pseudo-checkbox-selected-checkmark-color: #{colors.$white};
      --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #{colors.$gray-8};
    }
  }
}
