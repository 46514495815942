@mixin layout() {
  .modus-form-field {
    &.modus-form-field-type-slider {
      .modus-form-field-control {
        border: none;
        box-shadow: none !important;

        .mdc-slider {
          width: calc(100% - 24px);
        }
      }
    }

    &.modus-form-field-type-checkbox {
      .modus-form-field-control {
        border: none;
        box-shadow: none !important;
      }
    }
  }
}

@mixin core-theme($theme) {
  @include layout();
}
