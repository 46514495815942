@use 'sass:map';
@use 'utils' as utils;

@mixin color($config) {
  .cdk-overlay-pane {
    &.overlay {
      @apply bg-white p-3;
    }
  }
}

@mixin layout() {
  .cdk-overlay-pane {
    &.overlay-top,
    &.overlay-bottom {
      width: 100%;
    }

    &.overlay-left,
    &.overlay-right {
      height: 100%;
    }
  }
}

@mixin core-theme($theme) {
  $color-config: utils.get-colors($theme);

  @include color($color-config);
  @include layout();
}
