@use 'sass:map';
@use '@angular/material' as mat;
@use 'utils' as utils;

@mixin color($config) {
  $success: map.get($config, 'success');
  $warn: map.get($config, 'warn');
  $error: map.get($config, 'danger');

  .modus-icons {
    &.success {
      color: #{mat.m2-get-color-from-palette($success)};
    }

    &.warn {
      color: #{mat.m2-get-color-from-palette($warn)};
    }

    &.error {
      color: #{mat.m2-get-color-from-palette($error)};
    }
  }
}

@mixin sizes($config) {
  $icon-size: map.get($config, 'size');
  $icon-factors: map.get($config, 'factors');

  // Generate size classes based on factor map
  @each $size, $factor in $icon-factors {
    .modus-icons.icon-size-#{$size} {
      font-size: $icon-size * $factor;
    }
  }
}

@mixin core-theme($theme) {
  $icon-config: map.get($theme, 'icons');
  $color-config: utils.get-colors($theme);

  @include color($color-config);
  @include sizes($icon-config);
}
