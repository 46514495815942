@use 'sass:map';

@mixin layout($config) {
  $icon-size: map.get($config, 'size');

  .mat-mdc-progress-spinner {
    &.spinner-inline,
    &.spinner-inline svg {
      // stylelint-disable declaration-no-important
      display: inline-block !important;
      width: $icon-size !important;
      height: $icon-size !important;
      min-width: $icon-size !important;
      min-height: $icon-size !important;
      vertical-align: text-bottom;
    }
  }
}

@mixin core-theme($theme) {
  $icon-config: map.get($theme, 'icons');

  @include layout($icon-config);
}
